<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!--      &lt;!&ndash; Brand logo&ndash;&gt;-->
      <!--      <b-link class="brand-logo">-->
      <!--        <vuexy-logo />-->
      <!--        <h2 class="brand-text text-primary ml-1">-->
      <!--          Vuexy-->
      <!--        </h2>-->
      <!--      </b-link>-->
      <!--      &lt;!&ndash; /Brand logo&ndash;&gt;-->

      <!-- Left Text-->
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
          >
            Welcome to Alaalamy! 😀
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <b-alert
              variant="primary"
              show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Seller: </span> admin@alaalamy.com |
                  12345678</small>
              </p>
              <!--              <p>-->
              <!--                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>-->
              <!--              </p>-->
            </div>
            <feather-icon
                v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
                icon="HelpCircleIcon"
                size="18"
                class="position-absolute"
                style="top: 10; right: 10;"
            />
          </b-alert>

          <!-- form -->
          <validation-observer
              ref="loginForm"
              #default="{invalid}"
          >
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                  label="Email"
                  label-for="login-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                >
                  <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="alaalamy@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!--              &lt;!&ndash; checkbox &ndash;&gt;-->
              <!--              <b-form-group>-->
              <!--                <b-form-checkbox-->
              <!--                  id="remember-me"-->
              <!--                  v-model="status"-->
              <!--                  name="checkbox-1"-->
              <!--                >-->
              <!--                  Remember Me-->
              <!--                </b-form-checkbox>-->
              <!--              </b-form-group>-->

              <!-- submit buttons -->
              <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid || loading"
              >
                <span class="mr-1" v-if="loading">Loading...</span>
                <b-spinner v-if="loading" small/>
                <span v-if="!loading">Sign in</span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {required, email} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
import {getHomeRouteForLoggedInUser} from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthService from "@/services/AuthService";

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner

  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      AuthService: new AuthService(),
      loading: false,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          this.AuthService.login({
            email: this.userEmail,
            password: this.password,
          }).then(res => {
            this.loading = false
            res.user.role = res.user.roles[0].name.replace('_', ' ')
            res.user.ability = [{
              action: 'manage',
              subject: 'all',
            }]
            res.user.fullName = res.user.first_name + ' ' + res.user.last_name
            localStorage.setItem('accessToken', res.user.access_token)
            localStorage.setItem('userData', JSON.stringify(res.user))
            this.$ability.update(res.user.ability)
            // this.$router.push({name: 'all-offers'})
            window.location.href = 'all-offers'
          }).catch(err => {
            this.loading = false
            console.warn(err)
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
